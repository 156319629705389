<template>
  <div class="fill-height container--fluid" style="width: 100%">
    <div class="container--fluid">
      <v-toolbar tile flat style="max-height: 38px">
        <v-btn icon v-on:click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title v-on:click="goBack" style="cursor: pointer">Go back</v-toolbar-title>
        <v-spacer />

        <v-tooltip top v-if="profile.ret">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon plain large @click="goValidator">
              <v-icon color="success" size="36px">mdi-checkbox-multiple-marked-circle</v-icon>
            </v-btn>
          </template>
          <span>Validator</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon plain large @click="openDialog">
              <v-icon color="primary" size="36px">mdi-bookmark-check</v-icon>
            </v-btn>
          </template>
          <span>I'm interested</span>
        </v-tooltip>
        <v-tooltip top v-if="isFavorite">
          <template v-slot:activator="{ on, plain, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon plain large @click="toggleFavorite">
              <v-icon style="color: #f80359" size="36px">mdi-heart</v-icon>
            </v-btn>
          </template>
          <span>I like</span>
        </v-tooltip>
        <v-tooltip top v-else>
          <template v-slot:activator="{ on, plain, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon plain large @click="toggleFavorite">
              <v-icon style="color: #c95e85" size="36px">mdi-heart</v-icon>
            </v-btn>
          </template>
          <span>I like</span>
        </v-tooltip>
      </v-toolbar>
    </div>
    <v-container class="fill-height" fluid>
      <v-row align="start" justify="start">
        <v-col cols="12" md="5">
          <v-container class="mx-0" fluid>
            <v-row no-gutters>
              <v-col cols="12" class="mb-1">
                <span v-if="!loading" class="text-h5 font-weight-ligh headline text-uppercase">
                  {{ profile.name }}
                </span>
                <v-skeleton-loader v-else type="image" max-height="35px"></v-skeleton-loader>
              </v-col>
              <v-row v-if="profile.id" class="mb-1">
                <v-col cols="12" lg="6" md="12" sm="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="profile.typeEntity=='B'" disabled v-bind="attrs" v-on="on" large
                        @click="openReservationForm()" style="width: 100%; background-color: #691e32; color: white">
                        <!-- <v-icon color="green" size="44px">mdi-plus</v-icon> -->

                        <img src="@/assets/images/cart.svg" alt="cart" style="
                        object-fit: contain;
                        width: 24px;
                        height: 24px;
                        margin-right: 5%;
                        filter: brightness(0) invert(1);
                      " />

                        <h3 class="text-uppercase" style="color: white">
                          Add to itinerary
                        </h3>
                      </v-btn>
                      <v-btn v-if="profile.typeEntity != 'B'" :disabled="loading" v-bind="attrs" v-on="on" large
                        @click="openReservationForm()" style="width: 100%; background-color: #691e32; color: white">
                        <img src="@/assets/images/cart.svg" alt="cart" style="
                        object-fit: contain;
                        width: 24px;
                        height: 24px;
                        margin-right: 5%;
                        filter: brightness(0) invert(1);
                      " />

                        <h3 class="text-uppercase" style="color: white">
                          Add to itinerary
                        </h3>
                      </v-btn>
                    </template>
                    <span>Add to itinerary</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="profile.typeEntity == 'B'" disabled :href="`tel:${profile.phone1}`" v-bind="attrs"
                        v-on="on" large style="width: 100%;" color="success" @click="registerCallUSVisit">
                        <v-icon color="white" style="margin-right: 5%;">mdi-phone</v-icon>

                        <h3 class="text-uppercase" style="color: white">
                          Call us
                        </h3>
                      </v-btn>
                      <v-btn v-else :href="`tel:${profile.phone1}`" v-bind="attrs" v-on="on" large style="width: 100%;"
                        :disabled="loading" @click="registerCallUSVisit" color="success">
                        <v-icon color="white" style="margin-right: 5%;">mdi-phone</v-icon>

                        <h3 class="text-uppercase" style="color: white">
                          Call us
                        </h3>
                      </v-btn>
                    </template>
                    <span>Call us</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-col v-if="!loading" cols="12" class="mb-1 img-container">
                <v-carousel cycle :show-arrows="false">
                  <v-carousel-item v-for="item in profile.pictures" :key="item.id">
                    <v-img :lazy-src="require('@/assets/images/no-image.jpg')" :src="item.url"
                      class="img-doctor"></v-img>
                    <img v-if="profile.typeEntity == 'P'" src="@/assets/images/icons/premium.png" alt="sello"
                      class="superior-derecha" title="Premium" />
                    <img v-else-if="profile.typeEntity == 'B'" src="@/assets/images/icons/basic.png" alt="sello"
                      class="superior-derecha" title="Basic" />
                    <img v-else src="@/assets/images/icons/member.png" alt="sello" class="superior-derecha"
                      title="Member" />
                    <img v-if="profile.excellence" src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
                      class="superior-derecha-segunda" title="Excellence" />
                    <img v-if="profile.coparmex" src="@/assets/images/icons/coparmex.png" alt="sello1"
                      class="superior-derecha-tercera" title="Coparmex" />
                    <img v-if="profile.certified_surgeon" src="@/assets/images/icons/surgeon.jpg" alt="sello1"
                      class="superior-derecha-cuarta" title="Certified surgeon" />
                    <img v-if="profile.promoted" src="@/assets/images/icons/promoted.png" alt="sello1" class="top-left"
                      title="Promoted" />
                    <img v-if="profile.repsabi" src="@/assets/images/icons/repssabi.png" alt="sello1"
                      class="top-left-second" title="Repssabi" />
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col cols="12" v-else class="mb-1">
                <v-skeleton-loader type="image"></v-skeleton-loader>
              </v-col>

              <!-- Excelencia -->
              <v-col v-if="profile.excellence && profile.repsabi && !loading" cols="12">
                <div style="display: flex; align-items: center;justify-content: center; max-height: 110px; width: 100%"
                  class="mt-5">
                  <img usemap="#bcycoepris" src="@/assets/images/bcycoepris.png" alt="" class="img-qr-rigth" />
                  <a :href="profile.coepris_qr_link" target="_blank" class="ms-2">
                    <img :src="profile.coepris_qr" alt="qr" class="img-qr" />
                  </a>
                </div>
              </v-col>

              <!-- Turismo -->
              <v-col v-else-if="profile.excellence &&  profile.repsabi === null && !loading">
                <div style="display: flex; align-items: center;justify-content: center; max-height: 110px; width: 100%"
                  class="mt-5">
                  <!-- <a :href="profile.coepris_qr_link" target="_blank">
                  </a> -->
                  <img usemap="#only-catalogo" src="@/assets/images/only-catalogo.png" alt="" class="img-qr-rigth" />
                  <!--map name="only-catalogo">
                    <area shape="rect" coords="0,0,634,110" :href="profile.coepris_qr_link" :target="profile.target2"
                      alt="HTTP" />
                  </!--map-->
                  <a :href="profile.coepris_qr_link" target="_blank" class="ms-2"
                    v-if="profile.coepris_qr && profile.coepris_qr_link">
                    <img :src="profile.coepris_qr" alt="qr" class="img-qr" />
                  </a>
                </div>
              </v-col>

              <!-- Coepris -->
              <v-col v-else-if="profile.repsabi && profile.excellence === null && !loading">
                <div style="display: flex; align-items: center;justify-content: center; max-height: 110px; width: 100%"
                  class="mt-5">
                  <!-- <a :href="profile.coepris_qr_link" target="_blank">
                  </a> -->
                  <img usemap="#only-coepris" src="@/assets/images/only-coepris.png" alt="" class="img-qr-rigth" />
                  <!--map name="only-coepris">
                    <area shape="rect" coords="0,0,634,110" :href="'#'" alt="HTTP" />
                  </!--map-->
                  <a :href="profile.coepris_qr_link" target="_blank" class="ms-2">
                    <img :src="profile.coepris_qr" alt="qr" class="img-qr" />
                  </a>
                </div>
              </v-col>
              <v-col cols="12">
                <h3 class="ms-2">FAQ's</h3>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is COEPRIS?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      The COEPRIS (State Commission for Protection against Health Risks) in Mexico is a state-level
                      health authority responsible for regulating and ensuring the compliance of sanitary standards in
                      various sectors. Its main focus is on preventing and managing health risks related to food safety,
                      water quality, medical services, drugs, and general public health. COEPRIS works to protect the
                      population from health hazards by enforcing laws, conducting inspections, and promoting sanitary
                      practices.

                      <a target="_blank" href="https://www.gob.mx/cofepris">Go to COEPRIS</a>.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is the Secretary of Tourism?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      The Secretaría de Turismo de Baja California is responsible for promoting the development of the
                      state’s tourism industry. Its mission is to formulate, implement, and evaluate plans and programs
                      that foster sustainable tourism, attract investment, generate employment, and improve the quality
                      of life for residents. The agency works to position Baja California as a competitive destination
                      both nationally and internationally, focusing on key areas such as eco-tourism, cultural tourism,
                      and gastronomic tourism ￼ ￼.
                      For more information, you can visit their official site <a target="_blank"
                        href="https://www.bajacalifornia.gob.mx/secture/Conocenos/Mision">here</a>
                      ￼.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is State Catalog of Excellence in Health and Wellness Tourism (CEETSB):?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      CEETSB is a pioneering project developed by the Baja California Secretariat of Tourism (SECTURE).
                      Its purpose is to identify and promote globally the highest quality health and wellness service
                      providers in the region, complying with strict national and international standards of medical
                      care
                      and patient safety.

                      This initiative plays a crucial role in Baja California's strategy to promote the state as a
                      leader
                      in health and wellness tourism, allowing visitors to easily identify health establishments and
                      professionals
                      certified and accredited under current national and/or international health regulatory standards.

                      CEETSB also supports Baja California's status as the "Health Tourism Capital of Mexico", ensuring
                      that
                      both local residents and international visitors have access to safe and excellent medical and
                      wellness
                      services.

                      By being included in this catalog, health and wellness establishments and professionals
                      demonstrate
                      that they meet the highest standards of quality, care and service to the specialized market in
                      health
                      and wellness tourism with current international accreditations and certifications.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is The State Registry of Health and Wellness Service Providers (REPSSABI)?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      REPSSABI seeks to promote a culture of self-regulation in health care establishments through
                      the accompaniment and health promotion by COEPRIS, helping to strengthen the best health
                      practices,
                      the quality and safety of the medical services provided in our region. Its purpose is to ensure
                      that all health and wellness service providers in the state comply with strict safety, quality and
                      regulatory standards.

                      By being included in this registry, establishments such as hospitals, medical clinics, dental
                      offices,
                      recovery centers and medical spas demonstrate that they comply with their legal and technical
                      documentation
                      that supports their legality and good practices in the state.

                      For more details, you can visit the official site: <a target="_blank"
                        href="https://tramites.bajacalifornia.gob.mx/tu_belleza_con_certeza_4abf9f93-214d-4f25-a107-860c644576df">REPSSABI</a>
                      official site.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col v-if="!loading" cols="12">
                <v-container v-if="profile.typeEntity != 'B'" class="pa-0 mt-4" fluid>
                  <!--div :id="containerID" :style="containerCSS"></!--div-->
                  <Map :location="location" />
                  <v-btn depressed class="mt-1" style="background-color: #691e32; color: white" block
                    @click="goAddress">
                    <v-icon class="me-2">mdi-map-marker</v-icon>
                    How to get there
                  </v-btn>
                </v-container>
              </v-col>
              <v-col v-else cols="12">
                <v-skeleton-loader v-if="profile.typeEntity != 'B'" class="mt-2" type="image"></v-skeleton-loader>
                <v-skeleton-loader v-if="profile.typeEntity != 'B'" class="mt-2" height="45px"
                  type="image"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="7">
          <v-card class="ml-1" elevation="0" ou>
            <v-container class="mx-0" fluid>
              <v-row no-gutters v-if="profile.typeEntity != 'B' && !loading">
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Certifications: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" sm="12">
                  <ItemCertification v-for="certification in profile.certifications" :key="certification.id"
                    :certification="certification" />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <span class="text-button font-weight-medium">Description:
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading">
                      <div class="d-flex align-start">
                        <v-icon style="color:  #691e32" class="me-2">mdi-format-quote-close</v-icon>
                        <div class="text-container" :class="{ 'expanded': descriptionExpand }" ref="descriptionSpan">
                          <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                            {{
                            profile.description_english
                            ? profile.description_english
                            : profile.description
                            }}
                          </span>
                        </div>
                      </div>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader class="mt-1 mr-1 ml-1" type="paragraph"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Address: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-map-marker</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.address }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">E-mail: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-email</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.email }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Phones: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-phone</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        <a v-show="profile.phone1!==null && profile.phone1!=='null'" :href="'tel:' + profile.phone1">{{
                          profile.phone1 | formatPhone("MX") }} </a>
                        <span v-show="profile.phone2!==null && profile.phone2!=='null'">{{
                          profile.phone2 | formatPhone(" , ")
                          }}</span>
                        <a v-show="profile.phone2!==null && profile.phone2!=='null'" :href="'tel:' + profile.phone2">{{
                          profile.phone2 | formatPhone("US") }}</a>
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <span class="text-button font-weight-medium">Professional License Number:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.profesional_license_number }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Specialty License Number:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.specialty_license_number }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Valid Certification:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.certification_validity }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <span v-if="profile.website!==null" class="text-button font-weight-medium">Website:
                  </span>
                  <v-divider v-if="profile.website!==null"></v-divider>
                  <v-row v-if="profile.website!==null" align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-web</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        <a :href="profile.website" target="_blank">{{ profile.website }}</a>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <span class="text-button font-weight-medium">Certificate Number:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.certificate_number }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Professional License Verification:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.professional_license_verification }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Attention schedule:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col v-if="!loading" class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-clock</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.servicehours }}
                      </span>
                    </v-col>
                    <v-col v-else>
                      <v-skeleton-loader max-height="25px" class="mt-1 mr-1 ml-1" type="text"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>

                <SocialMedia :profile="profile" :loading="loading" />

                <v-col cols="12">
                  <v-row align="start" justify="start">
                    <v-col cols="12">
                      <div v-if="profile.specialties">
                        <span class="text-button font-weight-medium">Specialties:</span><br />
                        <v-skeleton-loader v-if="loading" height="35px" type="image"></v-skeleton-loader>
                        <v-chip v-else v-for="(s, i) in profile.specialties" :key="i" class="mr-1">
                          {{ s.name_english ? s.name_english : s.name }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row align="start" justify="start">
                    <v-col cols="12">
                      <v-row
                        v-if="profile.typeEntity != 'B' && profile.doctor_documents && profile.doctor_documents.length > 0"
                        no-gutters>
                        <v-col cols="12">
                          <span class="text-button font-weight-medium">Documents and awards: </span>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="12" v-if="loading">
                          <v-skeleton-loader class="mt-1" height="35px" type="image"></v-skeleton-loader>
                        </v-col>
                        <v-col v-else cols="12" sm="12">
                          <v-avatar size="80px" class="mr-1 mt-1" v-for="doc in profile.doctor_documents" :key="doc.id">
                            <a :title="doc.name" :href="doc.pdf_url" target="_blank">
                              <v-img :src="doc.url" :lazy-src="doc.url" alt="sello" class="documents-icon-card" />
                            </a>
                          </v-avatar>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="profile.typeEntity != 'B'" cols="12">
                  <v-container v-if="profile.videos != null && profile.videos.length > 0" class="pa-0 mt-1" fluid
                    @click="registerYoutubeVisit">
                    <span class="text-button font-weight-medium">Youtube video:</span><br />
                    <iframe v-if="profile.videos.length === 1" :src="profile.videos[0].url"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                      frameborder="0" height="520" width="100%">
                    </iframe>
                    <v-carousel v-else cycle hide-delimiters :show-arrows="profile.videos.length > 1">
                      <v-carousel-item v-for="item in profile.videos" :key="item.id">
                        <iframe :src="item.url"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen frameborder="0" height="520" width="100%">
                        </iframe>
                      </v-carousel-item>
                    </v-carousel>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="background-color: #691e32" fluid>
      <v-row>
        <v-col cols="12">
          <v-container class="ma-0" fill-height fluid>
            <v-row align="center" class="mt-3 text-center" justify="center">
              <v-col>
                <span class="text-h6 text-md-h4 text-uppercase white--text font-weight-bold">Our team of excellence
                  specialist doctors</span>
              </v-col>
            </v-row>
            <v-row class="mt-5 pt-0 mb-5">
              <v-col cols="12">
                <div class="fill-height">
                  <v-slide-group v-model="model" class="pa-1" active-class="white" show-arrows>
                    <v-slide-item v-for="card in doctors" :key="card.id">
                      <CardDoctor :item="card" class="mr-2 ml-2 mt-4 card-doctor-custom" @update-route="updatePage"/>
                    </v-slide-item>
                  </v-slide-group>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <footer-profile-component />
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row--dense mx-1 mt-2 modal-email">
            <h4 class="mb-3">Please share your email with us and we will contact you shortly</h4>
            <v-text-field v-model="email" :rules="emailRules" :error="email===''" label="Email"
              error-messages="Please enter an email" required>
            </v-text-field>
          </div>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDialog"> Cancel</v-btn>
            <v-btn :disabled="!valid" color="primary" text @click="acceptDialog">
              Accept
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" v-model="reservationFormDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Reservation times</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-3">
            <v-col>
              <label>Reservation date</label>
              <v-dialog ref="dialog1" v-model="modal3" :return-value.sync="reservation.date" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="reservation.date" label="Select a date" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="reservation.date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal3 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog1.save(reservation.date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Campo de hora -->
          <v-row>
            <v-col>
              <label>Reservation hour</label>
              <v-dialog ref="dialog" v-model="modal2" :return-value.sync="reservation.hour" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="reservation.hour" label="Select a hour"
                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="reservation.hour" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(reservation.hour)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeReservationForm">Cancel</v-btn>
          <v-btn color="success" @click="addToCart(profile)">Add appointment</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import doctorsService from "@/providers/DoctorsService";
import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
import preferencesService from "@/providers/PreferencesService";
import {changeInformationSEO} from "@/providers/KeywordsServices";
import Swal from 'sweetalert2'
import 'vue2-datepicker/index.css';
import {
  ADD_TO_ITINERARY_BUTTON,
  ADD_TO_ITINERARY,
  CALL_US,
  IM_INTEREST,
  LIKE,
  HOW_TO_GET_THERE,
  YOUTUBE_VIDEO,
  VISIT_PROFILE,
  PROFILE_NAME
} from "@/constant/doctor";
import { registerHistorial } from "@/providers/HistorialService";
import ItemCertification from "@/components/item-certification/ItemCertification";
import CardDoctor from "@/components/card-doctor/CardDoctor";
import Map from "@/components/maps/Map";
import SocialMedia from "@/components/social-media/SocialMedia";

export default {
  name: "DoctorProfileComponent",
  components: {
    FooterProfileComponent,
    ItemCertification, 
    CardDoctor,
    Map,
    SocialMedia
  },
  props: {
    doctor_id: Number,
    initialLocation: {
      type: Array,
      default: () => [-116.97480855890304, 32.547976944620714],
    },
    height: {
      type: String,
      default: "400px",
    },
    mapStyle: {
      type: String,
      default: "mapbox://styles/mapbox/streets-v11",
    },
    color: {
      type: String,
      default: "orange",
    },
    containerID: {
      type: String,
      default: "map",
    },
  },
  data: () => ({
    loading: true,
    modal2: false,
    modal3: false,
    showReadMore: false,
    establishmentName: '',
    reviews: [],
    error: null,

    descriptionExpand: false,
    model: null,
    youtubeVideo: "",
    snackBar: false,
    snackText: "",
    mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
    valid: true,
    dialog: false,
    email: null,
    location: [],
    doctors: [],
    profile: {
      id: 0,
      excellence:false,
      repsabi:false,
      typeEntity: "M",
      coparmex:false,
      folio: null,
      name: null,
      description: null,
      description_english: null,
      address: null,
      map_lng: null,
      map_lat: null,
      phone1: null,
      phone2: null,
      profesional_license_number: null,
      specialty_license_number: null,
      certification_validity: null,
      certificate_number: null,
      professional_license_verification: null,
      email: null,
      website: null,
      facebookprofile: null,
      instagramprofile: null,
      tweeterprofile: null,
      videos: [],
      servicehours: null,
      pictures: [],
      specialties: [],
      certifications: [],
      coepris_qr: "",
      vencimiento_fecha: "",
      coepris_qr_link: "",
      coepris2_qr_link: "",
      coepris2_qr: "",
      target2: "",
      tiktok: "",
      like: false,
      promoted: false,
      certified_surgeon: false,
      province: null,
      municipality: null,
    },
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],

    reservationFormDialog: false,
    reservation: {
      category: "doctors",
      id: "",
      date: "",
      hour: "",
    },
  }),
  computed: {
    containerCSS() {
      return {
        width: "100%",
        height: this.height,
      };
    },
    isFavorite() {
      return this.profile.like
    }
  },
  filters: {
    formatPhone(phn, country) {
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
              "USA +1 (" +
              phn.substring(0, 3) +
              ") " +
              phn.substring(3, 6) +
              "-" +
              phn.substring(6)
          );
        case "MX":
          return (
              "MEX +52 (" +
              phn.substring(0, 3) +
              ") " +
              phn.substring(3, 6) +
              "-" +
              phn.substring(6)
          );
        default:
          return country;
      }
    },
  },
  methods: {
    changeTitle(){
      let title = this.profile.name;
      if(this.profile.specialties && this.profile.specialties.length > 0){
        this.profile.specialties.forEach(e=>{
          title = `${title}, ${e.name}`;
        });
      }
      if (this.profile.municipality) {
        title = `${title}, ${this.profile.municipality.name}`;
      }
      if(this.profile.province){
        title = `${title}, ${this.profile.province.name}`;
      }
      title = `${title}, México`;
      this.$emit('update-title', title);
    },
    goValidator(){
      this.$router.push({
        name: "Validator",
        params: {
          folio: this.profile.ret
        }
      })
    },
    registerVisit(){
      registerHistorial(this.profile.id, PROFILE_NAME, VISIT_PROFILE);
    },
    registerYoutubeVisit() {
      registerHistorial(this.profile.id, PROFILE_NAME, YOUTUBE_VIDEO);
    },
    registerUbicationVisit() {
      registerHistorial(this.profile.id, PROFILE_NAME, HOW_TO_GET_THERE);
    },
    registerLikeVisit() {
      registerHistorial(this.profile.id, PROFILE_NAME, LIKE);
    },
    registerInterestVisit() {
      registerHistorial(this.profile.id, PROFILE_NAME, IM_INTEREST);
    },
    registerCallUSVisit() {
      registerHistorial(this.profile.id, PROFILE_NAME, CALL_US);
    },
    registerItineraryVisit() {
      registerHistorial(this.profile.id, PROFILE_NAME, ADD_TO_ITINERARY);
    },
    registerItineraryButtonVisit() {
      registerHistorial(this.profile.id, PROFILE_NAME, ADD_TO_ITINERARY_BUTTON);
    },
    getSpanHeight() {
      const altura = this.$refs.descriptionSpan.offsetHeight;
      console.log('Altura del span:', altura);
    },
    goAddress() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              this.userLocation = `${position.coords.latitude},${position.coords.longitude}`;
              this.openGoogleMaps();
            },
            (error) => {
              console.error("Error al obtener la ubicación:", error);
              console.log("No se pudo obtener la ubicación. Asegúrate de que los servicios de ubicación estén habilitados.");
            }
        );
      } else {
        console.log("La Geolocalización no es compatible con este navegador.");
      }
      this.registerUbicationVisit();
    },
    openGoogleMaps() {
      if (this.userLocation) {
        const url = `https://www.google.com/maps/dir/?api=1&origin=${this.userLocation}&destination=${this.profile.map_lat},${this.profile.map_lng}&travelmode=driving`;
        window.open(url, '_blank');
      }
      this.registerUbicationVisit();
    },
    openReservationForm() {
      this.reservationFormDialog = true;
      this.registerItineraryButtonVisit();
    },

    closeReservationForm() {
      this.reservationFormDialog = false;
    },

    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0];
      const fechaActual = new Date().toISOString().split("T")[0];
      return fechaVencimiento > fechaActual;
    },

    addToCart(profile) {
      if (!this.reservation.date || !this.reservation.hour) {
        this.snackText = "Select a date and time for the reservation.";
        this.snackBar = true;
      } else {
        this.reservationFormDialog = false;

        this.reservation.id = profile.id;

        const doctor = {
          category: "Doctor",
          name: profile.name,
          image: profile.pictures[0]?.url,
          profile: profile,
          reservation: this.reservation,
        };

        this.$store.dispatch("addToCart", doctor);
        this.registerItineraryVisit();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Reservation successfully added to itinerary.",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    goBack() {
      this.$router.push("/doctors");
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getDoctors() {
      doctorsService.getViewListByNumber(false, false, true, 'P').then((record) => {
        this.doctors = record.value.filter(e => e.slu !== this.$route.params.id);
      });
    },
    loadURL() {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = this.profile.youtubeprofile.split(
          /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      this.youtubeVideo = topOfQueue;
    },
    getProfile(idDoctor) {
      let record = null;
      this.loading = true;
      doctorsService.getRecordSlu(idDoctor).then((fetch_data) => {
        record = fetch_data.value;
        this.profile.excellence = parseInt(record.excellence) === 1
        this.profile.repsabi = parseInt(record.repsabi) === 1
        this.profile.typeEntity = record.typeEntity
        this.profile.coparmex = parseInt(record.coparmex) === 1
        this.profile.tiktok = record.tiktok
        this.profile.id = record.id;
        this.profile.name = record.name;
        this.profile.description = record.description;
        this.profile.description_english = record.description_english;
        this.profile.address = record.address;
        this.profile.map_lng = record.map_lng;
        this.profile.map_lat = record.map_lat;
        this.profile.email = record.email;
        this.profile.phone1 = record.phone1;
        this.profile.phone2 = record.phone2;
        this.profile.profesional_license_number = record.profesional_license_number;
        this.profile.specialty_license_number = !record.specialty_license_number || record.specialty_license_number == null || record.specialty_license_number == 'null'?'To be verified':record.specialty_license_number;
        this.profile.certification_validity = !record.certification_validity|| record.certification_validity == null || record.certification_validity == 'null'?'To be verified':record.certification_validity;
        this.profile.certificate_number = !record.certificate_number || record.certificate_number == null || record.certificate_number == 'null'?'To be verified':record.certificate_number;
        this.profile.professional_license_verification = !record.professional_license_verification || record.professional_license_verification == null || record.professional_license_verification == 'null'?'To be verified':record.professional_license_verification;
        this.profile.website = record.web_page;
        this.profile.folio = record.folio;
        this.profile.facebookprofile = record.facebook_profile;
        this.profile.instagramprofile = record.instagram_profile;
        this.profile.tweeterprofile = record.twitter_profile;
        this.profile.videos = record.videos;
        this.profile.servicehours = !record.servicehours || record.servicehours == null || record.servicehours == 'null'?'To be verified':record.servicehours;
        this.profile.pictures = record.pictures;
        this.profile.specialties = record.specialties;
        this.profile.certifications = record.certifications;
        this.profile.coepris_qr = record.coepris_qr;
        this.profile.vencimiento_fecha = record.vencimiento_fecha;
        this.profile.coepris_qr_link = record.coepris_qr_link;
        this.profile.doctor_documents = record.doctor_documents;
        this.profile.promoted = parseInt(record.promoted) === 1;
        this.profile.certified_surgeon = parseInt(record.certified_surgeon) === 1;
        this.profile.province = record.city;
        this.profile.municipality = record.municipality;

        if (this.profile.coepris_qr_link === "" || this.profile.coepris_qr_link === null) {
          this.profile.target2 = "#"
        }

        //this.getSpanHeight();
        this.checkFavorite();

        changeInformationSEO(record.keywords, record.description_english);
        this.changeTitle();

        const lng = this.profile.map_lng;
        const lat = this.profile.map_lat;
        this.setLocationCoordinates({lng, lat});
        //this.createMap();

        if (this.profile.youtubeprofile != null) {
          this.loadURL();
        }
        this.toTop();
        this.registerVisit();
      }).catch(err=>console.log(err)).finally(()=>{
        this.loading = false;
      });
    },
    async createMap() {
      try {
        mapboxgl.accessToken = this.mapBoxApiKey;
        // Create map object
        this.map = new mapboxgl.Map({
          container: this.containerID,
          style: this.mapStyle,
          center: this.location,
          zoom: 10,
        });
        // Controles basicos de navegacion: zoom y rotacion.
        this.map.addControl(new mapboxgl.NavigationControl());

        // Evento del mapa.
        this.map.on("load", () => {
          this.marker = new mapboxgl.Marker()
            .setLngLat(this.location)
            .addTo(this.map);
        });
      } catch (err) {
        console.error("map error", err);
      }
    },
    setLocationCoordinates(lngLat) {
      this.location = lngLat;
    },
    sendPreference(tag_type) {
      let tagText = tag_type === "mark" ? "Me interesa" : "Me Gusta";

      if (localStorage.getItem('likes')) {
        this.profile.likes = localStorage.getItem('likes');
        for (let i = 0; this.profile.likes.length - 1; i++) {
          if (this.profile.likes[i] == this.profile.name) {
            this.profile.like = true;
            break;
          } else {
            this.profile.likes.push(this.profile.name)
            localStorage.setItem(this.profile.likes);
            this.profile.like = true;
          }
        }
      } else {
        localStorage.likes = [];
        localStorage.setItem('likes', this.profile.name);
        this.profile.like = true;
      }
      preferencesService.addRecord(this.email, tag_type, 13).then((result) => {
        if (result.success) {
          this.snackText =
              tag_type === "mark"
                  ? `Se ha enviado los datos de esta vista etiquedada con ${tagText}! a su buzón de correo!`
                  : `La vista ha sido etiquetada con ${tagText}!`;

          // Enviar los datos por correo electronico.
          doctorsService.sendEmail(this.profile.id, this.email).then((data) => {
            if (data.success) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Thank you for sharing your email, we will be in contact as soon as possible.",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              console.log("Error enviando correo: ", data);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Error sharing your email.",
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
        } else {
          this.snackText = "Un error impidió etiquetar la vista!";
        }
        this.snackBar = true;
      });
    },
    openDialog() {
      this.email = "";
      this.dialog = true;
      this.registerInterestVisit();
    },
    closeDialog() {
      this.email = "";
      this.dialog = false;
    },
    acceptDialog() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.sendPreference("mark");
        this.dialog = false;
      }
    },
    addFavorite(favorites) {
      localStorage.setItem('doctors', JSON.stringify(favorites));
    },
    getFavorites() {
      const favoritesData = localStorage.getItem('doctors');
      if (favoritesData) {
        return JSON.parse(favoritesData);
      } else {
        return [];
      }
    },
    toggleFavorite() {
      const favorites = this.getFavorites()
      if (!favorites.includes(this.profile.id)) {
        favorites.push(this.profile.id)
      } else {
        const index = favorites.indexOf(this.profile.id);
        if (index > -1) {
          favorites.splice(index, 1);
        }
      }
      this.addFavorite(favorites);
      this.checkFavorite();
      this.registerLikeVisit();
    },
    checkFavorite() {
      const favorites = this.getFavorites()
      this.profile.like = favorites.includes(parseInt(this.profile.id));
    },
    updatePage(){
      this.getDoctors();
      this.getProfile(this.$route.params.id);
    }
  },
  mounted() {
    this.getDoctors();
    this.getProfile(this.$route.params.id);
  },
};
</script>

<style scoped>
.text-container {
  max-height: 200px;
  width: 100%;
  overflow: scroll;
  position: relative;
}

.text-container.expanded {
  height: auto;
}

.v-btn {
  text-transform: none !important;
}

.boxdivider {
  background-color: transparent;
  width: 100%;
  height: 15px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}

.bottom-gradient {
  background-image: linear-gradient(
      to bottom,
      rgba(114, 217, 235, 0.185) 0%,
      rgba(101, 99, 248, 0.911) 100%
  );
}

.my-slider > .v-slide-group__next.theme--light.v-icon {
  color: rgb(234, 10, 10) !important;
  font-size: 24pt !important;
}

.img-qr {
  height: 110px;
  width: 110px
}
.img-qr-rigth {
  width: 100%;
  background-color: #6a1b31;
  height: 110px;
  object-fit: contain
}
.img-container {
  height: 500px
}

.superior-derecha-card {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 50px;
  height: 50px;
}
.certification-icon-card {
  width: 130px;
  height: 100px;
  margin: 5px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}
.documents-icon-card {
  width: 130px;
  margin: 5px;
  vertical-align: top;
  cursor: pointer;
}
.superior-derecha-segunda-card {
  position: absolute;
  top: 35px;
  right: 0px;
  width: 60px;
  height: 60px;
}
.superior-derecha-tercera-card {
  position: absolute;
  top: 100px;
  right: 9px;
  width: 43px;
  height: 43px;
}

.img-doctor {
  object-fit: contain;
  object-position: top;
}

.top-left {
  position: absolute;
  top: 5px;
  left: 3px;
  width: 20;
  height: auto;
  border-radius: 8px;
}

.top-left-second {
  position: absolute;
  top: 75px;
  left: 3px;
  width: 57px;
  height: 57px;
  border-radius: 50% !important;
}

.superior-derecha {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 81px;
  height: 75px;
}
.superior-derecha-segunda {
  position: absolute;
  top: 85px;
  right: 5px;
  width: 78px;
  height: 78px;
}
.superior-derecha-tercera {
  position: absolute;
  top: 170px;
  right: 15px;
  width: 57px;
  height: 57px;
  border-radius: 50% !important;
}
.superior-derecha-cuarta {
  position: absolute;
  top: 240px;
  right: 15px;
  width: 57px;
  height: 57px;
  border-radius: 50% !important;
}

@media (max-width: 767px) {
  .img-container {
    height: 354px;
    overflow: hidden;
  }
  .img-doctor {
    object-fit: cover;
    height: 100%;
    object-position: top;
  }
}

.modal-email {
  padding: 30px;
}

.certification-icon-card-zoom {
  height: 500px;
  margin: 5px;
  object-fit: cover;
  object-position: center;
}

.zoom-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .img-qr {
    height: 60px;
    width: 60px
  }

  .img-qr-rigth {
    width: 100%;
    background-color: #6a1b31;
    height: 60px;
    object-fit: contain
  }
}
</style>
