<template>
  <doctor-profile-component @update-title="changeTitle"/>
</template>

<script>
import DoctorProfileComponent from "@/components/medical-services/DoctorProfileComponent";

export default {
  name: "DoctorProfile",
  components: { DoctorProfileComponent },
  title: "Doctor Profile | Baja California Health Tourism",
  methods: {
    changeTitle(e){
      this.title =e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
